@import '../../../../../defaults';

.anw-article-grid {
  display: grid;
  grid-auto-rows: 0fr 1fr;
  grid-column-gap: map-get($spacers, 3);
  grid-template-columns: 100px auto;
  grid-template-areas:
    'image title'
    'detail detail';

  @include media-breakpoint-up(sm) {
    grid-template-areas:
      'image title'
      'image detail';
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 138px auto;
  }
}

.anw-article-image {
  grid-area: image;
}

.anw-article-title {
  grid-area: title;
}

.anw-article-detail {
  grid-area: detail;
}

.anw-article-tile-image {
  background-position: center;
  background-size: cover;
  height: 180px;
}
